.SidebarNavList {
  list-style: none;
  margin: 0;
  padding: 0;

  &Item {
    box-sizing: border-box;
    padding: 0 var(--xs);
  }
}
