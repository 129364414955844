@import '@core/styles/vars';

.SidebarNav {
  --SidebarNav-top-offset: 15px; // This value aligns the "edit" mode sidebar with the "view" mode sidebar

  border-right: 1px solid var(--color-border-default);
  flex: 0 0 auto;
  padding: var(--SidebarNav-top-offset) 0 0;
  width: var(--hub-sidebar-width);

  &_loading {
    align-items: center;
    display: flex;
    font-size: 4rem;
    justify-content: center;
    padding: 0;
  }
}
