.SectionNav {
  $block: &;

  border-bottom: 1px solid var(--color-border-default);
  box-sizing: border-box;
  padding: var(--xs) 0;

  &-itemSettings {
    align-items: center;
    display: flex;
    overflow: hidden;
    transition: width var(--transition-fast);
    width: 0;

    &_active {
      overflow: initial;
      width: var(--button-xs);
    }

    &-menu {
      width: 250px;
    }

    &-toggle {
      display: grid;
      grid-template-columns: auto 1fr auto;
    }

    &-loading {
      font-size: 12px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
